<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-between">
      <label
        class="mr-1 h4"
        style="color:black"
      >Nombre de bon de livraison: {{ orders.length }}</label>

      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Recherche</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Recherche"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
        <b-form-group>
          <b-button
            v-if="user.role ==='supplier'"
            :to="{ name: 'order-add' }"
            variant="gradient-primary"
            class="ml-1 d-inline-block"
          >
            <feather-icon icon="PlusIcon" />
            <span class="ml-1">Ajouter colis</span>
          </b-button>
        </b-form-group>
        <b-form-group>
          <b-button
            variant="gradient-primary"
            class="ml-1 d-inline-block"
            :class="{ 'disabled-cursor': selectedOrders.selectedRows.length ===0 }"
            :disabled="selectedOrders.selectedRows.length ===0"
            @click="printAll"
          >
            <feather-icon icon="PrinterIcon" />
            <span class="ml-1">Imprimer</span>
          </b-button>
        </b-form-group>
      </div>
    </div>

    <!-- table -->
    <vue-good-table
      ref="my-table"
      :columns="columns"
      :rows="orders"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectionChanged"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div
          v-if="props.column.field === 'id'"
          class="text-nowrap"
        >
          <qrcode-vue
            style="display:inline"
            :value="props.row.id.toString()"
            :size="size"
            level="H"
          />

        </div>
        <span v-if="props.column.field === 'status'">
          <div v-if="props.row.status === 0">
            <b-badge
              class="badge-glow bg-warning bg-lighten-1"
              pill
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span> en attente</span>
            </b-badge>
          </div>
        </span>
        <span v-if="props.column.field === 'status'">
          <div v-if="props.row.status === 1">
            <b-badge
              class="badge-glow bg-warning bg-darken-2"
              pill
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span> en pickup </span>
            </b-badge>
          </div>
        </span>
        <!-- Column: Common -->

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Affichage 1 à </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1
              @input="
              (value) =
            > props.perPageChanged({ currentPerPage: value })
              "
              />
              <span class="text-nowrap"> de {{ props.total }} résultats trouvés </span>
            </b-form-select></div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import QrcodeVue from 'qrcode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BBadge,
    QrcodeVue,
  },
  data() {
    return {
      size: 80,
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'BL',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher bl',
          },
        },
        {
          label: 'Client',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher client',
          },
        },
        {
          label: 'Fournisseur',
          field: 'supplier.username',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher fournisseur',
          },
        },
        {
          label: 'Gouvernorat',
          field: 'governorate',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher gouvernorat',
          },
        },
        {
          label: 'Délégation',
          field: 'delegation',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher gouvernorat',
          },
        },
        {
          label: 'Téléhone',
          field: 'phone1',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher gouvernorat',
          },
        },
        {
          label: 'Etat',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher etat',
          },
        },
        {
          label: 'Prix TTC',
          field: 'price_ttc',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher ttc',
          },
        },
        {
          label: 'Date de création',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche date',
          },
        },
      ],
      user: {},
      orders: [],
      searchTerm: '',
      isLoading: false,
      selectedOrders: {
        selectedRows: [],
      },
    }
  },
  created() {
    this.getOrders()
    this.user = storeAuth.state.user
  },
  methods: {
    printAll() {
      const selected = this.$refs['my-table'].selectedRows
      if (selected.length === 0) {
        this.showToast('danger', 'top-center', 'vous devez sélectionner au minimum un colis')
      } else {
        const all = selected.map(x => x.id)
        this.$router.push({ name: 'print-all', params: { all } })
      }
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )} | ${h}`
    },
    async getOrders() {
      try {
        this.isLoading = true
        const { data } = await axios.get('/api/orders/get-delivery-notes/')
        this.orders = data
        this.isLoading = false
      } catch (error) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', error.toString())
        }, 1000)
      }
    },
    selectionChanged(selectedRows) {
      this.selectedOrders = selectedRows
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
