var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading === true)?_c('div',{staticClass:"text-center mb-2"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1):_vm._e(),_c('div',{staticClass:"custom-search d-flex justify-content-between"},[_c('label',{staticClass:"mr-1 h4",staticStyle:{"color":"black"}},[_vm._v("Nombre de bon de livraison: "+_vm._s(_vm.orders.length))]),_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Recherche")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Recherche","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)]),_c('b-form-group',[(_vm.user.role ==='supplier')?_c('b-button',{staticClass:"ml-1 d-inline-block",attrs:{"to":{ name: 'order-add' },"variant":"gradient-primary"}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"ml-1"},[_vm._v("Ajouter colis")])],1):_vm._e()],1),_c('b-form-group',[_c('b-button',{staticClass:"ml-1 d-inline-block",class:{ 'disabled-cursor': _vm.selectedOrders.selectedRows.length ===0 },attrs:{"variant":"gradient-primary","disabled":_vm.selectedOrders.selectedRows.length ===0},on:{"click":_vm.printAll}},[_c('feather-icon',{attrs:{"icon":"PrinterIcon"}}),_c('span',{staticClass:"ml-1"},[_vm._v("Imprimer")])],1)],1)],1)]),_c('vue-good-table',{ref:"my-table",attrs:{"columns":_vm.columns,"rows":_vm.orders,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm,
    },"select-options":{
      enabled: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'id')?_c('div',{staticClass:"text-nowrap"},[_c('qrcode-vue',{staticStyle:{"display":"inline"},attrs:{"value":props.row.id.toString(),"size":_vm.size,"level":"H"}})],1):_vm._e(),(props.column.field === 'status')?_c('span',[(props.row.status === 0)?_c('div',[_c('b-badge',{staticClass:"badge-glow bg-warning bg-lighten-1",attrs:{"pill":""}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v(" en attente")])],1)],1):_vm._e()]):_vm._e(),(props.column.field === 'status')?_c('span',[(props.row.status === 1)?_c('div',[_c('b-badge',{staticClass:"badge-glow bg-warning bg-darken-2",attrs:{"pill":""}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v(" en pickup ")])],1)],1):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Affichage 1 à ")]),_vm._v(" = > props.perPageChanged({ currentPerPage: value }) \" /> "),_c('span',{staticClass:"text-nowrap"},[_vm._v(" de "+_vm._s(props.total)+" résultats trouvés ")])]),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }